import React from "react"

import { Link } from 'gatsby'

import Layout from '@/components/Layout'
import Video from "@/utilities/Video"


const NotFoundPage = () => (
  <Layout
    pageTitle={"Pagina niet gevonden"}
    heroTitle="Oeps..."
    heroSubTitle=""
    heroTagLine="Fout 404: deze pagina werd niet gevonden"
    heroImage=""
    metaDescription="Deze pagina kunnen we niet vinden. Misschien is deze gaan trainen?"
  >


    <div className="container mb-5">
      <p>
        Deze pagina zou je nooit moeten zien. Je bezocht een pagina die niet bestaat, en dan krijg je deze in de plaats te zien.
        Mogelijke oorzaken:
          </p>
      <ul>
        <li>
          Deze pagina is verplaatst.
        </li>
        <li>
          Er zit een tikfout in het adres van deze pagina.
        </li>
        <li>
          Er is iets kapot.
        </li>
      </ul>

      <p>
        Wat kan je doen?
      </p>
      <ul>
        <li>
          Gebruik het menu om naar de pagina te gaan die je wil bezoeken.
        </li>
        <li>
          Meld de foute link aan de website-eigenaar langs waar je hier bent geraakt.
        </li>
        <li>
          Laat de website voor wat het is en kom <Link to='/trainingen'>trainen</Link>. Je bent welkom!
        </li>
        <li>
          Bekijk het onderstaande educatieve filmpje dat uitlegt waarom het internet soms kapot gaat.
        </li>
      </ul>
      <Video videoID="Vywf48Dhyns" />
    </div>
  </Layout>
)

export default NotFoundPage
