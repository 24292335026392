/**
 * Convert YT video to no-cookie + also loads after click
 *
 * @todo add better alt description
 *
 */

import React from 'react'

const Video = ({
  videoID,
  description,
  styleClass,
}: {
  videoID: string
  description?: string
  styleClass?: string
}) => {
  return (
    <div
      className={`${
        styleClass ? 'video ' + styleClass : 'video col-sm col-md-6 mb-5'
      }`}
    >
      <div className="embed-responsive embed-responsive-16by9">
        <iframe
          src={'https://www.youtube-nocookie.com/embed/' + `${videoID}`}
          srcDoc={
            '<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube-nocookie.com/embed/' +
            `${videoID}` +
            '?autoplay=1><img src=https://img.youtube.com/vi/' +
            `${videoID}` +
            "/hqdefault.jpg alt='" +
            `${description ? description : 'Jiu-Jitsu Aalst'}` +
            "' class='hosted-video'><span>▶</span></a>"
          }
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title={`${description ? description : 'Jiu-Jitsu Aalst'}`}
          loading="lazy"
          width="560"
          height="315"
          frameBorder="0"
        ></iframe>
      </div>
    </div>
  )
}

export default Video
